




















import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'OleMissPostLab7Crucible',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part7_1: null,
        part7_2: null,
      },
      questions: [
        {
          text: '1) In a few sentences, describe how to handle and use a crucible and its cover. Be sure to address the hazards and safety precautions associated with its use.',
          inputName: 'part7_1',
        },
        {
          text: '2) Write up a quick procedure for setting up and using a Bunsen Burner. Include how to make the flame "hotter" and "colder" with regard to the small blue cone of flame inside the larger flame.',
          inputName: 'part7_2',
        },
      ],
    };
  },
};
